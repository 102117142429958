<template>
  <ValidationObserver ref="addAdsObserver">
    <b-form>
      <ek-dialog
        title="إضافة إعلان"
        ref="adsDialog"
        :isEdit="false"
        @delete="deleteAds(adsDto.id)"
        @ok="onSubmit"
        @search="setSearch"
        @close="reset"
        placeholder="ابحث عن إعلان محدد"
        btnText="إعلان جديد"
      >
        <template #body>
          <div class="d-flex">
            <b-form-checkbox switch v-model="sendBy"
              >مرسل الاعلان : {{ sendBy ? "فعالية" : "ادارة التطبيق" }}</b-form-checkbox
            >
          </div>

          <ek-input-select
            v-if="sendBy"
            :options="activitiesList"
            :rules="[{ type: 'required', message: 'اسم الفعالبة مطلوب' }]"
            v-model="adsDto.senderId"
            label="اسم الفعالية"
            name="activityName"
          />
          <ek-input-text
            readonly
            v-if="sendBy"
            label="النشاط التجاري"
            name="CommercialActivity"
            v-model="activity.commercialName"
          />
          <ek-input-text
            v-if="sendBy"
            readonly
            label="المدينة"
            name="city"
            v-model="activity.location"
          />
          <ek-date-picker
            range
            v-model="adsDto.adsDatesList"
            :rules="[
              {
                type: 'required',
                message: 'تاريخ الإعلان مطلوب',
              },
            ]"
            label="تاريخ الإعلان"
            @input="updateAdsDate($event)"
            name="adsDate"
          />

          <ek-input-textarea
            :rules="[{ type: 'required', message: 'النص مطلوب' }]"
            label="النص"
            placeholder="ادخل النص"
            name="description"
            v-model="adsDto.content"
          />

          <ek-input-text
            :rules="[{ type: 'required', message: 'عنوان الإعلان' }]"
            label="عنوان الإعلان"
            placeholder="ادخل عنوان الإعلان"
            name="adTitle"
            v-model="adsDto.name"
          />
          <label style="font-size: 14px"> صورة الإعلان</label>
          <ek-input-image
            :required="true"
            label="صورة الاعلان"
            name="صور الإعلان"
            ref="adsImages"
            @input="updateAdsFile($event)"
          >
          </ek-input-image>
        </template>
      </ek-dialog>
    </b-form>
  </ValidationObserver>
</template>
<script>
import { shared } from "@global/shared";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";

export default {
  mixins: [shared],
  components: {
    ValidationObserver,
  },
  data() {
    return {
      sendBy: true,
      activity: {
        commercialName: "",
        locatiom: "",
      },
    };
  },
  computed: {
    ...mapState({
      adsDto: (state) => state.adsAndOffers.adsDto,
      senderId: (state) => state.adsAndOffers.adsDto.senderId,
      activitiesList: ({ activities }) => activities.activities,
    }),
    ...mapGetters(["selectedActivity"]),
  },
  methods: {
    getSelectedActivity() {
      return this.activitiesList.find((el) => el.id == this.adsDto.senderId);
    },
    switchSender(v) {
      this.adsDto.senderId = v ? this.userDetails.id : "";
    },
    ...mapMutations([
      "Reset_Ads_Dto",
      "Update_Ads_Image",
      "Update_Ads_Dto_Activity_Details",
      "Update_Ads_Dto_Date",
    ]),
    ...mapActions(["addAds"]),
    setSearch(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["name", "startDate", "endDate"],
        query,
      });
    },

    updateAdsDate($event) {
      this.Update_Ads_Dto_Date($event);
    },
    updateAdsFile($event) {
      this.adsDto.file = $event.file;
    },
    imageExist() {
      return this.adsDto.file && this.adsDto.file.name;
    },
    onSubmit() {
      this.$refs.addAdsObserver.validate().then((success) => {
        if (success && this.imageExist()) {
          let adsDetailsFormData = new FormData();

          adsDetailsFormData.append("senderId", this.adsDto.senderId);

          adsDetailsFormData.append("startDate", this.adsDto.startDate);

          adsDetailsFormData.append("endDate", this.adsDto.endDate);

          adsDetailsFormData.append("file", this.adsDto.file);

          adsDetailsFormData.append("content", this.adsDto.content);

          adsDetailsFormData.append("name", this.adsDto.name);

          this.addAds(adsDetailsFormData);

          this.$refs.adsDialog.close();
          this.activity = {
            commercialName: "",
            locatiom: "",
          };
        }
      });
    },
    setSelectedActivity(nv) {
      if (nv != this.userData.id)
        this.activity = this.activitiesList.find((el) => el.id == nv);
    },
    reset() {
      this.$refs.addAdsObserver.reset();
      this.Reset_Ads_Dto();
      this.activity = {
        commercialName: "",
        locatiom: "",
      };
      this.$refs["adsImages"].reset();
    },
    open() {
      this.$refs.adsDialog.open();
    },
    fireDeleteEvent(list) {
      this.deleteAdsList(list);
    },
    search(query) {
      this.$store.commit("Set_Search_Dto", {
        keys: ["firstName", "lastName", "phoneNumber"],
        query,
      });
    },
  },
  watch: {
    senderId(nv) {
      if (nv) this.setSelectedActivity(nv);
    },
    sendBy(nv) {
      console.log(nv);
      if (nv) this.adsDto.senderId = "";
      else this.adsDto.senderId = this.userData.id;
    },
  },
};
</script>

<style scoped lang="scss"></style>
